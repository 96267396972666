import React from "react"

import { SEOPage } from "../../components/SEO"
import { SubTitle, Description } from "../../components/typography"
import Layout from "../../components/layout"
import ContentGateway from "../../containers/ContentGateway"

const Content = () => (
  <>
    <SubTitle paddingTop="24px">
      Important red flag indicators that virtual asset service providers and
      financial institutions should know
    </SubTitle>
    <Description paddingTop="24px" paddingBottom="24px">
      Based on the Financial Action Task Force's Virtual Assets Red Flag
      Indiciators of Money Laundering and Terrorist Financing publication
    </Description>
  </>
)

export default () => {
  return (
    <Layout isSimple>
      <SEOPage title="FATF Red Flags" description="FATF Red Flags infographics"/>
      <ContentGateway
        buttonText="Download the infographic now"
        formUrl="https://usebasin.com/f/1df023c2f642.json"
        redirectUrl="/content/"
        title="FATF Red Flags"
        contentKey="23qwe"
        content={<Content />}
      />
    </Layout>
  )
}
