import React from "react"
import { Button } from "baseui/button"
import { FormControl } from "baseui/form-control"
import { StatefulInput } from "baseui/input"
import { StatefulCheckbox } from "baseui/checkbox"
import { navigate } from "gatsby"
import axios from "axios"
import { useForm } from "react-hook-form"
import { logContentEvent } from "../../utils/analytics"

const FormCapture = ({
  buttonText = "",
  formUrl,
  redirectUrl,
  contentKey,
  gtagLabel,
}) => {
  const { handleSubmit, register } = useForm()
  const onSubmit = data => {
    axios.post(formUrl, data).then(() => {
      logContentEvent("infographics", gtagLabel)
      navigate(redirectUrl, { state: { contentKey } })
    })
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <FormControl label="First Name*">
        <StatefulInput
          required
          name="firstName"
          placeholder=""
          inputRef={register}
        />
      </FormControl>
      <FormControl label="Last Name*">
        <StatefulInput
          required
          name="lastName"
          placeholder=""
          inputRef={register}
        />
      </FormControl>
      <FormControl label="Email*">
        <StatefulInput
          required
          type="email"
          name="email"
          placeholder=""
          inputRef={register}
        />
      </FormControl>
      <FormControl label="Company*">
        <StatefulInput
          required
          name="company"
          placeholder=""
          inputRef={register}
        />
      </FormControl>
      <FormControl label="Job Title*">
        <StatefulInput
          required
          name="title"
          placeholder=""
          inputRef={register}
        />
      </FormControl>
      <FormControl>
        <StatefulCheckbox
          name="subscribe"
          initialState={{ checked: true }}
          inputRef={register}
        >
          Subscribe to receive more information about our products/services,
          latest analysis and insights
        </StatefulCheckbox>
      </FormControl>
      <Button>{buttonText}</Button>
      {/* Honey Pot */}
      <div style={{ position: "absolute", left: "-5000px" }}>
        <input
          type="checkbox"
          name="free_range_forest_green_argyle_door"
          value="1"
          tabIndex="-1"
          autoComplete="no"
        />
      </div>
    </form>
  )
}

export default FormCapture
