import React from "react"
import { Block } from "baseui/block"
import { Cell } from "baseui/layout-grid"
import { Title } from "../../components/typography"
import FlushedGrid from "../../components/FlushedGrid"
import LeadCaptureForm from "../../components/LeadCaptureForm"
import Container from "../../components/UI/Container"

// form will redirect to content page with contentKey set as state

const ContentGateway = ({
  buttonText,
  formUrl,
  redirectUrl = "/content/",
  title,
  content,
  contentKey,
}) => (
  <Block marginTop="200px" marginBottom="200px">
    <Container>
      <FlushedGrid gridGutters={56}>
        <Cell span={[4, 4, 6]}>
          <Title>{title}</Title>
          {content}
        </Cell>
        <Cell span={[4, 4, 6]}>
          <LeadCaptureForm
            buttonText={buttonText}
            formUrl={formUrl}
            redirectUrl={redirectUrl}
            contentKey={contentKey}
            gtagLabel={title}
          />
        </Cell>
      </FlushedGrid>
    </Container>
  </Block>
)

export default ContentGateway
